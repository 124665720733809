import { FunctionComponent } from "react";
import { useSelector } from "../../../state/hooks";
import { getTranslator } from "../../../state/selector";

import TextIcon from "../../TextIcon/TextIcon";
import Button from "../../Button/Button";

import { numberOfBonusRoundQuestions } from "../../../lib/question/numberOfBonusRoundQuestions";
import { numberOfCorrectBonusRoundAnswers } from "../../../lib/question/numberOfCorrectBonusRoundAnswers";
import { score } from "../../../lib/question/score";

import { BonusRoundAnswer, BonusRoundQuestion } from "../../../lib/model";

interface BonusRoundOutroProps {
  onAcknowledge: () => void;
  question: BonusRoundQuestion;
  answer: BonusRoundAnswer;
}

const BonusRoundOutro: FunctionComponent<BonusRoundOutroProps> = ({
  onAcknowledge,
  question,
  answer,
}) => {
  const translate = useSelector(getTranslator);

  const totalCountOfBonusRoundQuestions = numberOfBonusRoundQuestions(question);
  const totalCountOfCorrentBonusRoundAnswers = numberOfCorrectBonusRoundAnswers(
    question,
    answer
  );
  const bonusRoundScore = score(question, answer);

  return (
    <div className="m-bonus-round__outro">
      <div className='m-bonus-round__outro__content'>
        <TextIcon icon="speedometer-high">
          {translate("bonusRound_iconText")}
        </TextIcon>
        <div className="m-bonus-round__outro__kicker -size-xl highlight">
          {translate("bonusRound_end_kicker", {
            one: totalCountOfCorrentBonusRoundAnswers,
            two: totalCountOfBonusRoundQuestions,
          })}
        </div>
        <h3 className="m-bonus-round__outro__headline -size-m text">
          {translate("bonusRound_end_headline")}
        </h3>
        <div className="m-bonus-round__outro__score">
          <i className="a-icon boschicon-bosch-ic-medal"></i>
          <div className="-size-4xl highlight">
            {bonusRoundScore.toLocaleString("de")}
          </div>
        </div>
      </div>
      <Button
        label={translate("bonusRound_end_cta")}
        icon="arrow-right"
        type="primary"
        onClick={onAcknowledge}
      />
    </div>
  );
};

export default BonusRoundOutro;
