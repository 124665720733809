import { FunctionComponent } from 'react';
import { Link } from '../../../../lib/model';

interface CardProps {
  link: Link;
}

const Card: FunctionComponent<CardProps> = ({ link }) => {
  return (
    <div className='m-card'>
      <div className='a-link a-link--button-secondary -icon'>
        <a
          aria-label='Open Look-alike secondary button with icon left Link Label'
          href={link.url}
          target={link.target || '_self'}
        >
          <i
            className='a-icon boschicon-bosch-ic-externallink'
            title='externallink'
          ></i>
          <span>{link.label}</span>
        </a>
      </div>
    </div>
  );
};

export default Card;
