import { createStore } from "redux";
import { allowedLanguages } from "../lib/language";
import {
  ACKNOWLEDGE_RESULT,
  ActionWithPayload,
  CONFIRM_ANSWER,
  HIDE_HINT,
  SELECT_BONUS_ROUND_OPTION,
  SELECT_LANGUAGE,
  SELECT_OPTION,
  SET_ANSWER_VALUE,
  SET_CONTENT,
  SET_FEEDBACKS,
  SET_QUESTIONS,
  SHOW_HINT,
  START_BONUS_ROUND,
  START_QUIZ,
} from "./actions";
import {
  Answer,
  BonusRoundAnswer,
  BonusRoundQuestion,
  BonusRoundSelection,
  Content,
  Feedbacks,
  FindingThingsQuestion,
  GuessingQuestion,
  MultipleChoiceImageQuestion,
  MultipleChoiceTextQuestion,
  Question,
  QuestionType,
  SingleChoiceImageQuestion,
  SingleChoiceTextQuestion,
} from "../lib/model";
import { getCurrentAnswer, getCurrentBonusRoundOption, getCurrentQuestion } from "./selector";
import { createInitialAnswer } from "../lib/question/createInitialAnswer";
export interface StateModel {
  currentLanguage: string;
  quizHasStarted: boolean;
  questions: (
    | SingleChoiceTextQuestion
    | SingleChoiceImageQuestion
    | MultipleChoiceTextQuestion
    | MultipleChoiceImageQuestion
    | GuessingQuestion
    | FindingThingsQuestion
    | BonusRoundQuestion
    | Question
  )[];
  showUnselectedAnswerWarning: boolean;
  showHint: boolean;
  answers: Answer[];
  translations: { [key: string]: string };
  content: Content | null;
  feedbacks: Feedbacks[];
}

const store = createStore(
  (
    state: StateModel = {
      // identifier of the language to use
      currentLanguage: "de_DE",
      // indicates that the quiz has started by user initiation
      quizHasStarted: false,
      // array of questions in this quiz, see the TS interfaces for details
      questions: [],
      // array containing information about given answers; contains an answer for each already-answered question
      // as well as one answer for the question currently displayed
      answers: [],
      // indicates that "please select an answer first" should be shown
      showUnselectedAnswerWarning: false,
      // stub - indicates that the hint should be shown (or the hint modal?)
      showHint: false,
      // lookup table for i18n strings
      translations: {
        startnow: "Jetzt loslegen!",
      },
      // content
      content: null,
      feedbacks: [],
    },
    action
  ): StateModel => {
    switch (action.type) {
      case START_QUIZ: {
        // when starting the quiz, create an answer for the first question,
        // so it shows up as the "current question" from here on
        const firstQuestion = state.questions[0];
        // if there is none, than this action is invalid
        // - do nothing, since there's no right thing to do here
        if (!firstQuestion) {
          return state;
        }
        const firstAnswer = createInitialAnswer(firstQuestion);
        return {
          ...state,
          // discard all other answers
          answers: [firstAnswer],
          // also, mark the quiz as started
          quizHasStarted: true,
        };
      }
      case SELECT_LANGUAGE: {
        const { lang } = action as ActionWithPayload<{ lang: string }>;

        // once the quiz has started, no language change is allowed anymore
        if (state.quizHasStarted) {
          return state;
        }

        // guard against invalid languages here
        if (!allowedLanguages.map((language) => language.id).includes(lang)) {
          return state;
        }

        return {
          ...state,
          currentLanguage: lang,
        };
      }

      case SET_QUESTIONS: {
        const { questions } = action as ActionWithPayload<{
          questions: Question[];
        }>;

        return {
          ...state,
          // replace the questions with the new ones
          questions,
        };
      }

      case SET_CONTENT: {
        const { content } = action as ActionWithPayload<{
          content: Content;
        }>;

        return {
          ...state,
          // replace the content
          content,
        };
      }

      case SELECT_OPTION: {
        const { optionId } = action as ActionWithPayload<{ optionId: string }>;
        const currentQuestion = getCurrentQuestion(state);

        // if there's no question, the action is invalid
        if (!currentQuestion) {
          return state;
        }

        const currentAnswer = getCurrentAnswer(state);

        // this should never happen, so let's throw an error here
        if (!currentAnswer) {
          throw new Error(
            "Could not find the current answer while handling " + SELECT_OPTION
          );
        }

        // @TODO check that optionId is valid for the given question

        const updatedAnswer = {
          ...currentAnswer,
        };

        // If a question is acknowledged, make the option unselectable
        if (null !== currentAnswer.confirmedAt) {
          return state;
        }

        switch (currentQuestion.type) {
          // for single choice questions (of either type), replace the selected option with the new one
          case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT:
          case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_IMAGE:
            updatedAnswer.optionIds = [optionId];
            break;
          // for multiple choice questions (of either type), toggle the selected option
          case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT:
          case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE: {
            if (updatedAnswer.optionIds.includes(optionId)) {
              // already selected? remove it
              updatedAnswer.optionIds = updatedAnswer.optionIds.filter(
                (id) => optionId !== id
              );
            } else {
              // not selected? add it
              updatedAnswer.optionIds = [...updatedAnswer.optionIds, optionId];
            }
            break;
          }
          // for finding things, options cannot be de-selected, so just add them if needed
          case QuestionType.QUESTION_TYPE_FINDING_THINGS: {
            if (!updatedAnswer.optionIds.includes(optionId)) {
              // not already selected? add it
              updatedAnswer.optionIds = [...updatedAnswer.optionIds, optionId];
            }
            break;
          }
          default:
            // any other question type does not support this - so throw an error here since
            // we should never arrive here
            throw new Error(
              "Question type " +
                currentQuestion.type +
                " could not be handled for action " +
                SELECT_OPTION
            );
        }

        return {
          ...state,
          // replace the updated answer in-place
          answers: state.answers.map((answer) =>
            answer.questionId === updatedAnswer.questionId
              ? updatedAnswer
              : answer
          ),
        };
      }

      case SET_ANSWER_VALUE: {
        const currentQuestion = getCurrentQuestion(state);

        if (
          !currentQuestion ||
          currentQuestion.type !== QuestionType.QUESTION_TYPE_GUESSING
        ) {
          return state;
        }

        const { value } = action as ActionWithPayload<{ value: number }>;

        return {
          ...state,
          answers: state.answers.map((answer) => {
            if (answer.questionId === currentQuestion.id) {
              return {
                ...answer,
                value,
              };
            }

            return answer;
          }),
        };
      }

      case CONFIRM_ANSWER: {
        const currentQuestion = getCurrentQuestion(state);
        const currentAnswer = getCurrentAnswer(state);

        // if there is no current question, there's nothing to confirm
        if (!currentQuestion) {
          return state;
        }

        // if there is a current question, but no current answer, something went wron
        if (!currentAnswer) {
          throw new Error(
            "Could not find the current answer while handling " + CONFIRM_ANSWER
          );
        }

        // do we have enough options to confirm the answer?
        switch (currentQuestion.type) {
          case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT:
          case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_IMAGE:
          case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT:
          case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE:
          case QuestionType.QUESTION_TYPE_FINDING_THINGS:
            // all these types do need at least one option
            if (currentAnswer.optionIds.length === 0) {
              return { ...state, showUnselectedAnswerWarning: true };
            }
        }

        // all other types don't!
        // @TODO: is this right?
        return {
          ...state,
          showUnselectedAnswerWarning: false,
          answers: state.answers.map((answer) =>
            answer.questionId === currentQuestion.id
              ? {
                  ...answer,
                  confirmedAt: Date.now(),
                }
              : answer
          ),
        };
      }
      case ACKNOWLEDGE_RESULT: {
        const currentQuestion = getCurrentQuestion(state);
        const currentAnswer = getCurrentAnswer(state);

        // without a current question, there's nothing to acknowledge
        if (!currentQuestion) {
          return state;
        }

        // if there is a current question, but no current answer, something went wrong
        if (!currentAnswer) {
          throw new Error(
            "Could not find the current answer while handling " + CONFIRM_ANSWER
          );
        }

        // only confirmed answers can be acknowledged
        if (null === currentAnswer.confirmedAt) {
          return state;
        }

        // replace the current answer object with a confirmed one
        const stateWithAcknowledegedAnswer = {
          ...state,
          answers: state.answers.map((answer) =>
            answer.questionId === currentQuestion.id
              ? {
                  ...answer,
                  acknowledged: true,
                }
              : answer
          ),
        };

        // next, we want to create a new answer object for the next question

        // get the next question in line
        const nextQuestion = getCurrentQuestion(stateWithAcknowledegedAnswer);

        // if there's none, we don't have anything to do
        if (!nextQuestion) {
          return stateWithAcknowledegedAnswer;
        }

        // create the new answer object and store it
        const nextQuestionAnswer = createInitialAnswer(nextQuestion);

        return {
          ...stateWithAcknowledegedAnswer,
          answers: stateWithAcknowledegedAnswer.answers.concat([
            nextQuestionAnswer,
          ]),
        };
      }
      case SHOW_HINT: {
        return {
          ...state,
          showHint: true,
        };
      }
      case HIDE_HINT: {
        return {
          ...state,
          showHint: false,
        };
      }
      case SET_FEEDBACKS: {
        const { feedbacks } = action as ActionWithPayload<{
          feedbacks: Feedbacks[];
        }>;

        return {
          ...state,
          feedbacks,
        };
      }
      case START_BONUS_ROUND: {
        const currentQuestion = getCurrentQuestion(state);
        if (
          !currentQuestion ||
          currentQuestion.type !== QuestionType.QUESTION_TYPE_BONUS_ROUND
        ) {
          return state;
        }

        const currentAnswer = getCurrentAnswer(state);

        if (!currentAnswer) {
          return state;
        }

        if (currentAnswer.startedAt !== null) {
          return state;
        }

        const updatedAnswer: BonusRoundAnswer = {
          ...(currentAnswer as BonusRoundAnswer),
          startedAt: Date.now(),
        };

        // startedAt: Date.now()
        return {
          ...state,
          answers: state.answers.map((answer) => {
            if (answer.questionId === updatedAnswer.questionId) {
              return updatedAnswer;
            }

            return answer;
          }),
        };
      }
      case SELECT_BONUS_ROUND_OPTION: {
        const { optionId, selection } = action as ActionWithPayload<{
          optionId: string;
          selection: BonusRoundSelection;
        }>;

        const currentQuestion = getCurrentQuestion(state);
        if (
          !currentQuestion ||
          currentQuestion.type !== QuestionType.QUESTION_TYPE_BONUS_ROUND
        ) {
          return state;
        }

        const currentAnswer = getCurrentAnswer(state);

        if (!currentAnswer) {
          return state;
        }

        if (currentAnswer.startedAt === null) {
          return state;
        }

        const updatedAnswer: BonusRoundAnswer = {
          ...(currentAnswer as BonusRoundAnswer),
          values: (currentAnswer as BonusRoundAnswer).values.concat([
            {
              optionId,
              selected: selection,
            },
          ]),
        };

        const stateWithUpdatedAnswer = {
          ...state,
          answers: state.answers.map((answer) => {
            if (answer.questionId === updatedAnswer.questionId) {
              return updatedAnswer;
            }

            return answer;
          }),
        };

        const nextOption = getCurrentBonusRoundOption(stateWithUpdatedAnswer);

        if(nextOption) {
          return stateWithUpdatedAnswer;
        } else {
          // if there are no more options, close the bonus round
          updatedAnswer.confirmedAt = Date.now();

          return {
            ...state,
            answers: state.answers.map((answer) => {
              if (answer.questionId === updatedAnswer.questionId) {
                return updatedAnswer;
              }
  
              return answer;
            }),
          };
        }
      }
      default:
        return state;
    }
  },
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
