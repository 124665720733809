export interface Option {
  id: string;
  text: string;
}

export enum QuestionType {
  QUESTION_TYPE_SINGLE_CHOICE_TEXT = "QUESTION_TYPE_SINGLE_CHOICE_TEXT",
  QUESTION_TYPE_SINGLE_CHOICE_IMAGE = "QUESTION_TYPE_SINGLE_CHOICE_IMAGE",
  QUESTION_TYPE_MULTIPLE_CHOICE_TEXT = "QUESTION_TYPE_MULTIPLE_CHOICE_TEXT",
  QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE = "QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE",
  QUESTION_TYPE_GUESSING = "QUESTION_TYPE_GUESSING",
  QUESTION_TYPE_FINDING_THINGS = "QUESTION_TYPE_FINDING_THINGS",
  QUESTION_TYPE_BONUS_ROUND = "QUESTION_TYPE_BONUS_ROUND",
}

export interface Question {
  id: string;
  type: QuestionType;
  options: Option[];
  headline: string;
  text: string;
  hint: string;
  explanation: string;
  imageUrl: string;
}

interface ImageOption extends Option {
  url: string;
  isCorrect: boolean;
}
interface TextOption extends Option {
  values: string[];
  isCorrect: boolean;
}

interface SingleChoiceQuestion<OptionType extends Option> extends Question {
  options: OptionType[];
}

interface MultipleChoiceQuestion<OptionType extends Option> extends Question {
  options: OptionType[];
}

export interface SingleChoiceTextQuestion
  extends SingleChoiceQuestion<TextOption> {
  type: QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT;
}

export interface SingleChoiceImageQuestion
  extends SingleChoiceQuestion<ImageOption> {
  type: QuestionType.QUESTION_TYPE_SINGLE_CHOICE_IMAGE;
}

export interface MultipleChoiceTextQuestion
  extends MultipleChoiceQuestion<TextOption> {
  type: QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT;
}

export interface MultipleChoiceImageQuestion
  extends MultipleChoiceQuestion<ImageOption> {
  type: QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE;
}

export interface GuessingQuestion extends Question {
  type: QuestionType.QUESTION_TYPE_GUESSING;
  min: number;
  max: number;
  initial: number;
  step?: number;
  minCorrect: number;
  maxCorrect: number;
  correctValueLabel: string;
  imageUrl: string;
}

interface FindingThingsOption extends Option {
  x: number;
  y: number;
}

export interface FindingThingsQuestion extends Question {
  type: QuestionType.QUESTION_TYPE_FINDING_THINGS;
  imageUrl: string;
  options: FindingThingsOption[];
}

export type BonusRoundSelection = "LEFT" | "RIGHT";

export interface BonusRoundOption extends Option {
  imageUrl: string;
  correctSelection: BonusRoundSelection;
}

export interface BonusRoundQuestion extends Question {
  type: QuestionType.QUESTION_TYPE_BONUS_ROUND;
  options: BonusRoundOption[];
}

export interface BasicAnswer {
  questionId: string;
  optionIds: string[];
  confirmedAt: number | null;
  acknowledged: boolean;
  startedAt: number | null;
}

export interface GuessingAnswer extends BasicAnswer {
  value: number;
}

export interface BonusRoundAnswerValue {
  optionId: string;
  selected: BonusRoundSelection;
}

export interface BonusRoundAnswer extends BasicAnswer {
  values: BonusRoundAnswerValue[];
}

export type Answer = BasicAnswer | GuessingAnswer | BonusRoundAnswer;

/**
 * CONTENT MODEL
 */

export interface Link {
  target: "blank" | null;
  label: string;
  url: string;
}

export interface Card {
  imageUrl: string;
  copy: string;
  cta: Link;
}

export interface FooterContent {
  copyright: string;
  links: Link[];
}
export interface IntroContent {
  headline: string;
  text: string;
  hinweisText: string;
  cta: string;
  imageUrl: string;
  modulHeadline: string;
  modulText: string;
  linkmoduls: Card[];
}
export interface OutroContent extends IntroContent {
  headline1: string;
  headline2: string;
  scoreLabel: string;
  feedbackLabel: string;
  feedbackEmail: string;
}

export interface Content {
  footer: FooterContent;
  intro: IntroContent;
  outro: OutroContent;
}

export type FeedbackType = 'positive' | 'negative';

export interface Feedbacks {
  questionType: string;
  options: string[];
  type: FeedbackType,
}

/**
 * I18N MODELS
 */

export type Translations = { [key: string]: string };

export type Dictionaries = { [lang: string]: Translations };
