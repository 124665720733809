import * as React from "react";
import {
  Answer,
  GuessingAnswer,
  GuessingQuestion,
  MultipleChoiceImageQuestion,
  MultipleChoiceTextQuestion,
  Question as QuestionModel,
  QuestionType,
  SingleChoiceImageQuestion,
  SingleChoiceTextQuestion,
  FindingThingsQuestion,
  BonusRoundQuestion,
  BonusRoundAnswer,
} from "../../lib/model";
import { ChoiceQuestion } from "../ChoiceQuestion/ChoiceQuestion";
import Guessing from "../Guessing/Guessing";
import FindingThings from "../FindingThings/FindingThings";
import BonusRound from "../BonusRound/BonusRound";

export interface QuestionProps {
  question: QuestionModel;
  answer: Answer | null;
  showUnselectedAnswerWarning: boolean;
}

const component = (
  question: QuestionModel,
  answer: Answer,
  showUnselectedAnswerWarning: boolean
) => {
  switch (question.type) {
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT:
      return (
        <ChoiceQuestion
          question={question as SingleChoiceTextQuestion}
          answer={answer}
          optionType="text"
          showUnselectedAnswerWarning={showUnselectedAnswerWarning}
        />
      );
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_IMAGE:
      return (
        <ChoiceQuestion
          question={question as SingleChoiceImageQuestion}
          answer={answer}
          optionType="image"
          showUnselectedAnswerWarning={showUnselectedAnswerWarning}
        />
      );
    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT:
      return (
        <ChoiceQuestion
          question={question as MultipleChoiceTextQuestion}
          answer={answer}
          multiple
          optionType="text"
          showUnselectedAnswerWarning={showUnselectedAnswerWarning}
        />
      );
    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE:
      return (
        <ChoiceQuestion
          question={question as MultipleChoiceImageQuestion}
          answer={answer}
          multiple
          optionType="image"
          showUnselectedAnswerWarning={showUnselectedAnswerWarning}
        />
      );
    case QuestionType.QUESTION_TYPE_GUESSING:
      return (
        <Guessing
          question={question as GuessingQuestion}
          answer={answer as GuessingAnswer}
          showUnselectedAnswerWarning={showUnselectedAnswerWarning}
        />
      );
    case QuestionType.QUESTION_TYPE_FINDING_THINGS:
      return(
        <FindingThings question={question as FindingThingsQuestion} answer={answer}/>
      );
    case QuestionType.QUESTION_TYPE_BONUS_ROUND:
      return(
        <BonusRound showUnselectedAnswerWarning={showUnselectedAnswerWarning} question={question as BonusRoundQuestion} answer={answer as BonusRoundAnswer}/>
      );
    default:
      return "Unknown question type " + question.type;
  }
};

export const Question: React.FunctionComponent<QuestionProps> = ({
  question,
  answer,
  showUnselectedAnswerWarning,
}) => {
  if (!answer) {
    throw new Error(
      "Tried to render a question with id " +
        question.id +
        " without an answer object."
    );
  }

  return (
    <>
      {component(question, answer, showUnselectedAnswerWarning)}
      {/* <hr></hr>
      QUESTION DEBUG <pre>{JSON.stringify(question, null, 2)}</pre>
      selectedOptions DEBUG{" "}
      <pre>{JSON.stringify(selectedOptions, null, 2)}</pre> */}
    </>
  );
};
