import { FunctionComponent } from "react"

const SuperHappyIcon: FunctionComponent = () => {
  return(
    <svg height="64px" width="64px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" className="a-super-happy-icon">
      <path d="M96,16c-44.1,0-80,35.9-80,80s35.9,80,80,80s80-35.9,80-80S140.1,16,96,16z M96,168c-39.7,0-72-32.3-72-72s32.3-72,72-72 s72,32.3,72,72S135.7,168,96,168z"/>
      <polygon points="81.8,91.3 72,84.8 62.2,91.3 57.8,84.7 72,75.2 86.2,84.7 "/>
      <polygon points="129.8,91.3 120,84.8 110.2,91.3 105.8,84.7 120,75.2 134.2,84.7 "/>
      <path d="M133.2,113.2l5.7,5.7c-0.7,0.7-17.5,17.2-42.8,17.2S54,119.6,53.3,118.9l5.7-5.7c0,0.1,15,14.8,37,14.8 C118.1,128,133,113.3,133.2,113.2z"/>
    </svg>
  )
}

export default SuperHappyIcon