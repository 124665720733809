import { Content, Question, Feedbacks, BonusRoundSelection } from "../lib/model";

export const START_QUIZ = "START_QUIZ";
export const SELECT_LANGUAGE = "SELECT_LANGUAGE";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_FEEDBACKS = "SET_FEEDBACKS";
export const SET_CONTENT = "SET_CONTENT";
export const SELECT_OPTION = "SELECT_OPTION";
export const SET_ANSWER_VALUE = "SET_ANSWER_VALUE";
export const CONFIRM_ANSWER = "CONFIRM_ANSWER";
export const ACKNOWLEDGE_RESULT = "ACKNOWLEDGE_RESULT";
export const SHOW_HINT = "SHOW_HINT";
export const HIDE_HINT = "HIDE_HINT";
export const START_BONUS_ROUND = "START_BONUS_ROUND";
export const SELECT_BONUS_ROUND_OPTION = "SELECT_BONUS_ROUND_OPTION";

export interface Action {
  type: string;
}

export type ActionWithPayload<Extension> = Action & Extension;

export interface SelectLanguageAction extends Action {
  lang: string;
}

export const startQuiz = () => ({
  type: START_QUIZ,
});

export const selectLanguage = (lang: string) => ({
  type: SELECT_LANGUAGE,
  lang,
});

export const setQuestions = (questions: Question[]) => ({
  type: SET_QUESTIONS,
  questions,
});

export const selectOption = (optionId: string) => ({
  type: SELECT_OPTION,
  optionId,
});

export const confirmAnswer = () => ({
  type: CONFIRM_ANSWER,
});

export const acknowledgeResult = () => ({
  type: ACKNOWLEDGE_RESULT,
});

export const setContent = (content: Content) => ({
  type: SET_CONTENT,
  content,
});

export const setFeedbacks = (feedbacks: Feedbacks[]) => ({
  type: SET_FEEDBACKS,
  feedbacks,
});

export const setAnswerValue = (value: number) => ({
  type: SET_ANSWER_VALUE,
  value,
});

export const showHint = () => ({
  type: SHOW_HINT,
});

export const hideHint = () => ({
  type: HIDE_HINT,
});

export const startBonusRound = () => ({
  type: START_BONUS_ROUND,
});

export const selectBonusRoundOption = (
  optionId: string,
  selection: BonusRoundSelection
) => ({
  type: SELECT_BONUS_ROUND_OPTION,
  optionId,
  selection,
});
