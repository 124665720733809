import { Answer } from "../model";

export const timeElapsed = (answer: Answer): number => {
  if (null === answer.startedAt) {
    return 0;
  }

  if (null === answer.confirmedAt) {
    return Date.now() - answer.startedAt;
  }

  return answer.confirmedAt - answer.startedAt;
};
