import { FunctionComponent } from "react";

import BonusRoundIntro from "./BonusRoundIntro/BonusRoundIntro";
import BonusRoundContent from "./BonusRoundContent/BonusRoundContent";
import BonusRoundOutro from "./BonusRoundOutro/BonusRoundOutro";

import { BonusRoundAnswer, BonusRoundQuestion } from "../../lib/model";

import { QuestionProps } from "../Question/Question";
import { useDispatch, useSelector } from "../../state/hooks";
import {
  getBonusRoundHasEnded,
  getBonusRoundHasStarted,
  getCurrentBonusRoundOption,
} from "../../state/selector";
import {
  acknowledgeResult,
  // confirmAnswer,
  selectBonusRoundOption,
  // selectOption,
  startBonusRound,
} from "../../state/actions";
export interface BonusRoundProps extends QuestionProps {
  question: BonusRoundQuestion;
  answer: BonusRoundAnswer;
}

const BonusRound: FunctionComponent<BonusRoundProps> = ({
  question,
  answer,
}) => {
  const dispatch = useDispatch();
  const bonusRoundHasStarted = useSelector(getBonusRoundHasStarted);
  const bonusRoundHasEnded = useSelector(getBonusRoundHasEnded);
  const currentBonusRoundOption = useSelector(getCurrentBonusRoundOption);

  return (
    <div className="m-bonus-round">
      {!bonusRoundHasStarted && (
        <BonusRoundIntro onStart={() => dispatch(startBonusRound())} />
      )}
      {bonusRoundHasStarted && !bonusRoundHasEnded && (
        <BonusRoundContent
          onLeft={() =>
            dispatch(
              selectBonusRoundOption(currentBonusRoundOption!.id, "LEFT")
            )
          }
          onRight={() =>
            dispatch(
              selectBonusRoundOption(currentBonusRoundOption!.id, "RIGHT")
            )
          }
          option={currentBonusRoundOption!}
          question={question}
          answer={answer}
        />
      )}
      {bonusRoundHasStarted && bonusRoundHasEnded && (
        <BonusRoundOutro
          onAcknowledge={() => dispatch(acknowledgeResult())}
          question={question}
          answer={answer}
        />
      )}
    </div>
  );
};

export default BonusRound;
