import { FunctionComponent } from "react";
import classNames from "classnames";

type AllowedSingleMultipleChoiceType = "checkbox" | "radio-button";

interface SingleMultipleChoiceProps {
  type: AllowedSingleMultipleChoiceType;
  label: string;
  id: string;
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const SingleMultipleChoice: FunctionComponent<SingleMultipleChoiceProps> = ({
  type,
  label,
  id,
  checked,
  disabled = false,
  onClick = () => {},
}) => {
  const SingleMultipleChoiceClass = classNames([
    `m-choice-question__list-item__${type}`,
    `a-${type}`,
  ]);
  return (
    <div className={SingleMultipleChoiceClass}>
      <input
        onClick={onClick}
        checked={checked}
        disabled={disabled}
        name={type === "checkbox" ? "questionCheckbox" : "questionRadio"}
        type={type === "radio-button" ? "radio" : "checkbox"}
        id={id}
        readOnly
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default SingleMultipleChoice;
