export const allowedLanguages = [
    {
        id: "de_DE",
        label: "DE",
    }, 
    {
        id: "en_US",
        label: "EN"
    }
];
