import {
  acknowledgeResult,
  confirmAnswer,
  selectOption,
} from '../../state/actions';
import { useDispatch, useSelector } from '../../state/hooks';
import Button from '../Button/Button';
import TextImageChoice from './TextImageChoice/TextImageChoice';
import SingleMultipleChoice from './SingleMultipleChoice/SingleMultipleChoice';
import Feedback from '../Feedback/Feedback';
import Dialog from '../Dialog/Dialog';

import { QuestionProps } from '../Question/Question';

import { showHint } from '../../state/actions';
import {
  MultipleChoiceImageQuestion,
  MultipleChoiceTextQuestion,
  QuestionType,
  SingleChoiceImageQuestion,
  SingleChoiceTextQuestion,
} from '../../lib/model';
import {
  currentAnswerIsCorrect,
  getTranslator,
  shouldShowHint,
} from '../../state/selector';
import classNames from 'classnames';

export interface ChoiceQuestionProps extends QuestionProps {
  optionType: 'text' | 'image';
  multiple?: boolean;
  question:
    | SingleChoiceImageQuestion
    | SingleChoiceTextQuestion
    | MultipleChoiceImageQuestion
    | MultipleChoiceTextQuestion;
}

const controlAndLabel = ({
  multiple,
  id,
  selectedOptions,
  text,
  dispatch,
  disabled,
}: {
  multiple: boolean;
  id: string;
  selectedOptions: string[];
  text: string;
  dispatch: ReturnType<typeof useDispatch>;
  disabled: boolean;
}) => {
  return multiple ? (
    <SingleMultipleChoice
      type='checkbox'
      id={`checkbox-${id}`}
      onClick={() => dispatch(selectOption(id))}
      checked={selectedOptions.includes(id)}
      disabled={disabled}
      label={text}
    />
  ) : (
    <SingleMultipleChoice
      type='radio-button'
      id={`radio-button-${id}`}
      onClick={() => dispatch(selectOption(id))}
      checked={selectedOptions.includes(id)}
      disabled={disabled}
      label={text}
    />
  );
};

export const ChoiceQuestion: React.FunctionComponent<ChoiceQuestionProps> = ({
  question,
  // optionType,
  // multiple,
  answer,
  showUnselectedAnswerWarning,
}) => {
  const dispatch = useDispatch();

  const { headline } = question;

  const translate = useSelector(getTranslator);

  const showDialog = useSelector(shouldShowHint);

  const answerIsCorrect = useSelector(currentAnswerIsCorrect);

  if (!answer) {
    throw new Error(
      'Tried to render a choice question with id ' +
        question.id +
        ' without an answer object.'
    );
  }

  const optionType =
    question.type === QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT ||
    question.type === QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT
      ? 'text'
      : 'image';

  const multiple =
    question.type === QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE ||
    question.type === QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT
      ? true
      : false;

  const selectedOptions = answer?.optionIds;

  const numberOfCorrectAnswers = Object.values(question.options).reduce(
    (counter, currentOption) => {
      if (currentOption.isCorrect) ++counter;
      return counter;
    },
    0
  );

  const clue = multiple ? (
    <span className='m-choice-question__clue'>
      ({translate('multiple_clue')} {numberOfCorrectAnswers})
    </span>
  ) : null;

  return (
    <div className='m-choice-question'>
      {null === answer.confirmedAt && (
        <>
          <h1 className='m-choice-question__headline'>{headline}</h1>
          {clue}
          {question.imageUrl && (
            <figure className='m-choice-question__image a-image'>
              <div>
                <img src={question.imageUrl} alt='Lorem ipsum dolor sit amet' />
              </div>
            </figure>
          )}{' '}
        </>
      )}
      {null !== answer.confirmedAt && (
        <Feedback positive={answerIsCorrect} questionType={question.type} />
      )}
      <div
        className={`m-choice-question__list ${
          multiple ? '-multiple' : '-single'
        } ${optionType === 'image' ? '-images' : ''}`}
      >
        {optionType === 'text'
          ? (
              question as SingleChoiceTextQuestion | MultipleChoiceTextQuestion
            ).options.map(({ id, text, values, isCorrect }) => {
              const itemClassName = classNames([
                'm-choice-question__list-item',
                {
                  '-correct': null !== answer.confirmedAt && isCorrect,
                  '-incorrect': null !== answer.confirmedAt && !isCorrect,
                },
              ]);

              return (
                <div className={itemClassName} key={id}>
                  {values && (
                    <TextImageChoice
                      type='text'
                      id={id}
                      values={values}
                      checked={selectedOptions.includes(id)}
                      onClick={() => dispatch(selectOption(id))}
                    />
                  )}
                  {controlAndLabel({
                    multiple,
                    id,
                    selectedOptions,
                    text,
                    dispatch,
                    disabled: false,
                  })}
                </div>
              );
            })
          : (
              question as
                | SingleChoiceImageQuestion
                | MultipleChoiceImageQuestion
            ).options.map(({ id, text, url, isCorrect }) => {
              const itemClassName = classNames([
                'm-choice-question__list-item',
                {
                  '-correct': null !== answer.confirmedAt && isCorrect,
                  '-incorrect': null !== answer.confirmedAt && !isCorrect,
                },
              ]);

              return (
                <div className={itemClassName} key={id}>
                  <TextImageChoice
                    type='image'
                    id={id}
                    imageUrl={url}
                    checked={selectedOptions.includes(id)}
                    onClick={() => dispatch(selectOption(id))}
                  />
                  {controlAndLabel({
                    multiple,
                    id,
                    selectedOptions,
                    text,
                    dispatch,
                    disabled: false,
                  })}
                </div>
              );
            })}
      </div>
      {showUnselectedAnswerWarning && (
        <div className='a-notification -warning' role='alert'>
          <i className='a-icon ui-ic-alert-warning' title='Loren Ipsum'></i>
          <div
            id='notification-label-id-bar-warning-warning'
            className='a-notification__content'
          >
            {translate('show_notification_warning')}
          </div>
        </div>
      )}
      {null !== answer.confirmedAt && (
        <div className='a-notification -neutral' role='alert'>
          <div
            id='notification-label-id-bar-default-notification-neutral'
            className='a-notification__content'
          >
            {question.explanation}
          </div>
        </div>
      )}
      {null === answer.confirmedAt && (
        <div className='m-choice-question__buttons'>
          <Button
            type='primary'
            label={translate('answer_cta')}
            onClick={() => dispatch(confirmAnswer())}
          ></Button>
          <Button
            icon='wand-user'
            type='secondary'
            label={translate('help_cta')}
            onClick={() => dispatch(showHint())}
          ></Button>
        </div>
      )}
      {null !== answer.confirmedAt && (
        <div className='m-choice-question__buttons'>
          <Button
            type='primary'
            icon='arrow-right'
            label={translate('next_question_cta')}
            onClick={() => dispatch(acknowledgeResult())}
          ></Button>
          <div className='a-link a-link--button-secondary -icon'>
            <a
              aria-label='Open Look-alike secondary button with icon right Link Label'
              href={question.hint}
              target='_blank'
              rel='noreferrer'
            >
              <i
                className='a-icon boschicon-bosch-ic-externallink'
                title='external-link'
              ></i>
              <span>{translate('know_more_cta')}</span>
            </a>
          </div>
        </div>
      )}
      <Dialog hint={question.hint} open={showDialog} />
    </div>
  );
};
