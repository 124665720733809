import { FunctionComponent } from 'react';

interface CardContainerProps {
  headline: string;
  text: string;
  children: React.ReactNode;
}

const CardContainer: FunctionComponent<CardContainerProps> = ({
  children,
  headline,
  text,
}) => {
  return (
    <div className='m-card-container'>
      <h2>{headline}</h2>
      <p>{text}</p>
      <div className='m-cards'>{children}</div>
    </div>
  );
};

export default CardContainer;
