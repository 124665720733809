import { Answer, Question } from "../model";
import { getAnswer } from "./getAnswer";

/**
 * @returns true if the given question is completed with the given list of answers
 */
export const isComplete = (question: Question, answers: Answer[]) => {
  const answer = getAnswer(question, answers);

  // no answer means that the question is not complete
  if (!answer) {
    return false;
  }

  // otherwise, return true if the answer is acknowledged
  return answer.acknowledged;
};
