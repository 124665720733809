import { FunctionComponent } from "react";

interface ProgressIndicatorProps {
  quizPercentageDone: number
}

const ProgressIndicator: FunctionComponent<ProgressIndicatorProps> = ({
  quizPercentageDone
}) => {
  return (
  <div className='a-progress-indicator-container'>  
    <progress
      className="a-progress-indicator -determinate"
      id="content-progress"
      value={quizPercentageDone}
      max="100"
    ></progress>
      <div className="a-progress-indicator__inner-bar" style={{ 'width': + quizPercentageDone + '%' }}></div>
    </div>
  );
};

export default ProgressIndicator;