import {
  Question,
  Answer,
  QuestionType,
  SingleChoiceImageQuestion,
  SingleChoiceTextQuestion,
  MultipleChoiceImageQuestion,
  MultipleChoiceTextQuestion,
  FindingThingsQuestion,
  GuessingAnswer,
  GuessingQuestion,
  BonusRoundQuestion,
  BonusRoundAnswer,
} from "../model";

import { timeElapsed } from "../answer/timeElapsed";
import { numberOfCorrectBonusRoundAnswers } from "./numberOfCorrectBonusRoundAnswers";

const rawScore = (question: Question, answer: Answer): number => {
  if (null === answer.confirmedAt) {
    return 0;
  }

  return rawScoreForUnconfirmedAnswer(question, answer);
}

const rawScoreForUnconfirmedAnswer = (question: Question, answer: Answer): number => {
  switch (question.type) {
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_IMAGE:
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT: {
      const options = (
        question as SingleChoiceImageQuestion | SingleChoiceTextQuestion
      ).options as {
        id: string;
        isCorrect: boolean;
      }[];

      const correctOption = options.find((option) => option.isCorrect);

      if (!correctOption) {
        return 0;
      }

      return answer.optionIds[0] === correctOption.id ? 1 : 0;
    }

    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE:
    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT: {
      const options = (
        question as MultipleChoiceImageQuestion | MultipleChoiceTextQuestion
      ).options as {
        id: string;
        isCorrect: boolean;
      }[];

      const correctOptions = options.filter((option) => option.isCorrect);
      const correctOptionIds = correctOptions.map(({ id }) => id);

      const correctlySelectedOptions = answer.optionIds.filter((selectedId) =>
        correctOptionIds.includes(selectedId)
      );
      const inCorrectlySelectedOptions = answer.optionIds.filter(
        (selectedId) => !correctOptionIds.includes(selectedId)
      );

      // one wrong answer -> no points
      if (inCorrectlySelectedOptions.length > 0) {
        return 0;
      }

      if (correctlySelectedOptions.length === correctOptionIds.length) {
        return 1;
      }

      if (correctlySelectedOptions.length >= correctOptionIds.length / 2) {
        return 0.5;
      }

      return 0;
    }

    case QuestionType.QUESTION_TYPE_GUESSING: {
      const selectedValue = (answer as GuessingAnswer).value;
      const minimumCorrectValue = (question as GuessingQuestion).minCorrect;
      const maximumCorrectValue = (question as GuessingQuestion).maxCorrect;

      return minimumCorrectValue <= selectedValue &&
        maximumCorrectValue >= selectedValue
        ? 1
        : 0;
    }

    case QuestionType.QUESTION_TYPE_FINDING_THINGS: {
      const options = (question as FindingThingsQuestion).options;
      const correctOptionsIds = options.map(({ id }) => id);

      const correctlySelectedOptions = answer.optionIds.filter((selectedId) =>
        correctOptionsIds.includes(selectedId)
      );

      const ratio = correctlySelectedOptions.length / question.options.length;

      if (ratio === 1) {
        return 1;
      }
      if (ratio > 0.75) {
        return 0.75;
      }
      if (ratio > 0.5) {
        return 0.5;
      }
      if (ratio > 0.25) {
        return 0.25;
      }

      return 0;
    }

    case QuestionType.QUESTION_TYPE_BONUS_ROUND: {
      return numberOfCorrectBonusRoundAnswers(question as BonusRoundQuestion, answer as BonusRoundAnswer);
    }

    default: {
      throw new Error("Cannot score a question with type " + question.type);
    }
  }
};

const timeFactor = (question: Question, answer: Answer): number => {
  const elapsedTime = timeElapsed(answer);

  if (0 === elapsedTime) {
    return 0;
  }

  if (question.type === QuestionType.QUESTION_TYPE_BONUS_ROUND) {
    if (elapsedTime < 20000) {
      return 2;
    } else if (elapsedTime < 30000) {
      return 1.75;
    } else if (elapsedTime < 40000) {
      return 1.5;
    } else if (elapsedTime < 50000) {
      return 1.2;
    } else if (elapsedTime < 60000) {
      return 1.1;
    } else {
      return 1;
    }
  } else {
    if (elapsedTime < 5000) {
      return 2;
    } else if (elapsedTime < 10000) {
      return 1.75;
    } else if (elapsedTime < 15000) {
      return 1.5;
    } else if (elapsedTime < 20000) {
      return 1.3;
    } else if (elapsedTime < 25000) {
      return 1.2;
    } else if (elapsedTime < 30000) {
      return 1.15;
    } else if (elapsedTime < 45000) {
      return 1.05;
    } else {
      return 1;
    }
  }
};

const scoreFactor = (question: Question): number => {
  return question.type === QuestionType.QUESTION_TYPE_BONUS_ROUND ? 250 : 1000;
};

export const score = (question: Question, answer: Answer) => {
  const raw = rawScore(question, answer);

  return Math.round(scoreFactor(question) * raw * timeFactor(question, answer));
};

export const liveScoreWithoutTimeFactor = (question: Question, answer: Answer) => {
  const raw = rawScoreForUnconfirmedAnswer(question, answer);

  return Math.round(scoreFactor(question) * raw);
}