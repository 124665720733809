import { FunctionComponent, useState } from 'react';
import TextIcon from '../TextIcon/TextIcon';
import ProgressIndicator from './ProgressIndicator/ProgressIndicator';
import useInterval from '@use-it/interval';

import { useSelector } from '../../state/hooks';
import {
  getBonusScore,
  getCurrentAnswer,
  getNonBonusScore,
  getProgress,
  quizTimeForCompletedAnswers,
} from '../../state/selector';

import { format } from 'date-fns/fp';

const Header: FunctionComponent = () => {
  const progress = useSelector(getProgress) || 0;

  const currentAnswer = useSelector(getCurrentAnswer);

  /**
   * get the elapsed time for all completed answers - that is all answers _before_ this one
   * since the timer has been stopped for these answers, this value will only change once
   * the current answer becomes completed
   */
  const elapsedTime = useSelector(quizTimeForCompletedAnswers);

  /**
   * keep track of the elapsed time for the current answer in a local state
   * since we want to avoid regular, timed state updates
   */
  const [liveTime, setLiveTime] = useState(0);

  /**
   * the total time to be displayed:
   * - if the current answer is confirmed, read from the state as above and do nothing else
   * - else, add the live time calculated below
   *
   * this is necessary since currentAnswer.confirmedAt is updated immediately, and then, liveTime
   * is already inclusded in elapsedTime, but we do not want to count that twice.
   */
  const totalTime =
    currentAnswer && currentAnswer.confirmedAt !== null
      ? elapsedTime
      : elapsedTime + liveTime;

  /**
   * regularly recalculate the elapsed time for this answer by comparing the current time to the
   * time the answer was started
   */
  useInterval(
    () => {
      // for confirmed or non-started answer, treat this as no time elapsed
      if (
        null === currentAnswer ||
        null !== currentAnswer.confirmedAt ||
        null === currentAnswer.startedAt
      ) {
        setLiveTime(0);
        return;
      }

      setLiveTime(Date.now() - currentAnswer.startedAt);
    },
    /**
     * the update interval for the live time:
     * - in order to display the advancing time without "hiccups", we choose the smallest sensible number
     * - at 60 updates per second (presumed maximum update rate of the UI), this value is 1 second / 60 = 0.16666666…
     *   or roughly 16 milliseconds
     */
    16
  );

  /**
   * finally, apply formatting to the result
   */
  const timeLabel = format('mm:ss')(totalTime);

  const nonBonusScore = useSelector(getNonBonusScore);
  const bonusScore = useSelector(getBonusScore);

  return (
    <header className='o-header'>
      <div className='e-container'>
        <div className='o-header__content'>
          <ProgressIndicator quizPercentageDone={progress}></ProgressIndicator>
          <div className='o-header__textIcons'>
            <TextIcon icon='alarm-clock'>{timeLabel}</TextIcon>
            <TextIcon icon='wishlist'>
              {nonBonusScore.toLocaleString('de')}
            </TextIcon>
            <TextIcon icon='medal'>{bonusScore.toLocaleString('de')}</TextIcon>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
