import { Dictionaries } from "../lib/model";

/**
 * a look-up-table for for fallbacks, in this example: english falls back to german
 */
const fallbacks: { [lang: string]: string } = {
  en_US: "de_DE",
};

type InterpolationParameters = {
  [paramKey: string]: { toString: () => string };
};

/**
 * replaces markers like '{key}' with 'value' in the given template string
 */
const interpolate = (
  template: string,
  params?: InterpolationParameters
): string => {
  if (!params) {
    return template;
  }

  return Object.keys(params).reduce((partiallyInterpolatedTemplate, key) => {
    return partiallyInterpolatedTemplate.replace(
      "{" + key + "}",
      params[key].toString() as string
    );
  }, template);
};

/**
 * @returns a translated string,
 */
export const translate = (
  dictionaries: Dictionaries,
  targetLanguage: string,
  key: string,
  params?: InterpolationParameters
): string => {
  if (!dictionaries[targetLanguage]) {
    throw new Error("No dictionary for " + targetLanguage + " found.");
  }

  if (dictionaries[targetLanguage][key]) {
    return interpolate(dictionaries[targetLanguage][key], params);
  } else {
    const fallbackLanguage = fallbacks[targetLanguage];
    if (!fallbackLanguage) {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          "Key " +
            key +
            " has no translation for language " +
            targetLanguage +
            " and no fallback, dumping key."
        );
      }
      return key;
    } else {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          "Key " +
            key +
            " has no translation for language " +
            targetLanguage +
            ", falling back to " +
            fallbackLanguage
        );
      }
      return translate(dictionaries, fallbackLanguage, key, params);
    }
  }
};

export type Translator = (
  key: string,
  params?: InterpolationParameters
) => string;

type TranslatorFactory = (
  dictionaries: Dictionaries,
  targetLanguage: string
) => Translator;

/**
 * @returns a translator function with fixed dictionary and target language
 */
export const createTranslate: TranslatorFactory =
  (dictionaries, targetLanguage) => (key, params) =>
    translate(dictionaries, targetLanguage, key, params);
