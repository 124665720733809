import SliderPoints from './SliderPoints/SliderPoints';
import Feedback from '../Feedback/Feedback';
import Button from '../Button/Button';
import Dialog from '../Dialog/Dialog';

import { FunctionComponent } from 'react';
import { GuessingAnswer, GuessingQuestion } from '../../lib/model';
import { useDispatch } from 'react-redux';
import {
  acknowledgeResult,
  confirmAnswer,
  setAnswerValue,
  showHint,
} from '../../state/actions';
import { useSelector } from '../../state/hooks';
import {
  currentAnswerIsCorrect,
  getTranslator,
  shouldShowHint,
} from '../../state/selector';
import { QuestionProps } from '../Question/Question';

export interface GuessingProps extends QuestionProps {
  question: GuessingQuestion;
  answer: GuessingAnswer;
}

const Guessing: FunctionComponent<GuessingProps> = ({
  question,
  answer,
  showUnselectedAnswerWarning,
}) => {
  const dispatch = useDispatch();

  const translate = useSelector(getTranslator);

  const correct = useSelector(currentAnswerIsCorrect);

  const showDialog = useSelector(shouldShowHint);

  return (
    <div className='m-guessing'>
      {answer.confirmedAt === null && (
        <>
          <h1 className='m-guessing__headline'>{question.headline}</h1>
          <figure className='a-image'>
            <div className='a-image__ratioWrapper'>
              <img src={question.imageUrl} alt='!' />
            </div>
          </figure>
          <SliderPoints
            minPoints={question.min}
            maxPoints={question.max}
            value={answer.value}
            onChange={(value) => dispatch(setAnswerValue(value))}
            step={question.step}
          />
        </>
      )}
      {answer.confirmedAt !== null && (
        <>
          <Feedback positive={correct} questionType={question.type} />
          <div className='a-result -size-4xl highlight'>
            {question.correctValueLabel}
          </div>
        </>
      )}

      {showUnselectedAnswerWarning && (
        <div className='a-notification -warning' role='alert'>
          <i className='a-icon ui-ic-alert-warning' title='Loren Ipsum'></i>
          <div
            id='notification-label-id-bar-warning-warning'
            className='a-notification__content'
          >
            {translate('show_notification_warning')}
          </div>
        </div>
      )}
      {answer.confirmedAt !== null && (
        <div className='a-notification -neutral' role='alert'>
          <div
            id='notification-label-id-bar-default-notification-neutral'
            className='a-notification__content'
          >
            {question.explanation}
          </div>
        </div>
      )}
      <div className='m-guessing__buttons'>
        {null === answer.confirmedAt && (
          <>
            <Button
              type='primary'
              label={translate('answer_cta')}
              onClick={() => dispatch(confirmAnswer())}
            ></Button>
            {question.hint && (
              <Button
                icon='wand-user'
                type='secondary'
                label={translate('help_cta')}
                onClick={() => dispatch(showHint())}
              ></Button>
            )}
          </>
        )}
        {null !== answer.confirmedAt && (
          <>
            <Button
              icon='arrow-right'
              type='primary'
              label={translate('next_question_cta')}
              onClick={() => dispatch(acknowledgeResult())}
            ></Button>
            <div className='a-link a-link--button-secondary -icon'>
              <a
                aria-label='Open Look-alike secondary button with icon right Link Label'
                href={question.hint}
                target='_blank'
                rel='noreferrer'
              >
                <i
                  className='a-icon boschicon-bosch-ic-externallink'
                  title='external-link'
                ></i>
                <span>{translate('know_more_cta')}</span>
              </a>
            </div>
          </>
        )}
      </div>
      <Dialog hint={question.hint} open={showDialog} />
    </div>
  );
};

export default Guessing;
