import { FunctionComponent } from "react";
import classNames from "classnames";

type AllowedTextImageChoiceType = "text" | "image";

interface BasicTextImageChoiceProps {
  type: AllowedTextImageChoiceType;
  id: string;
  checked: boolean;
  onClick: () => void;
}

interface TextChoiceProps extends BasicTextImageChoiceProps {
  values: string[];
}

interface ImageChoiceProps extends BasicTextImageChoiceProps {
  imageUrl: string;
}

type TextImageChoiceProps = TextChoiceProps | ImageChoiceProps;

const TextImageChoice: FunctionComponent<TextImageChoiceProps> = (props) => {
  const { id, type, checked, onClick } = props;

  const textImageChoiceClass = classNames([
    `m-choice-question__list-item__${type}`,
    { "-secondary -size-l highlight": type === "text", "-checked": checked },
  ]);

  if (type === "text") {
    const { values } = props as TextChoiceProps;

    return (
      <div className={textImageChoiceClass} onClick={onClick}>
        {values.map((value) => (
          <div key={value}>{value}</div>
        ))}
      </div>
    );
  } else {
    const { imageUrl } = props as ImageChoiceProps;
    return (
      <div className={textImageChoiceClass} onClick={onClick}>
        <figure className="a-image">
          <div className="a-image__ratioWrapper">
            <img src={imageUrl} alt={id} />
          </div>
        </figure>
      </div>
    );
  }
};

export default TextImageChoice;
