import { Fragment, FunctionComponent, useEffect, useState } from 'react';

import { QuestionType } from '../../lib/model';
import { useSelector } from '../../state/hooks';
import { getFeedbacks, getTranslator } from '../../state/selector';

import SuperHappyIcon from '../SuperHappyIcon/SuperHappyIcon';

// Match all the four cases
// 1. ! whitespace letter
// 2. ! letter
// 3. .(dot) whitespace letter
// 4. .(dot) letter
const REGEX_SENTENCE_SPLIT = /(!|\.)(\s*)([A-Z])/g;
const REPLACE_SENTENCE_SPLIT_WITH_MARKER = '$1 $2###$3';

/**
 * @returns the given string, split along sentence ends and beginnings.
 * @see REGEX_SENTENCE_SPLIT for details how this is done
 */
const breakString = (stringWithoutLineBreaks: string) =>
  stringWithoutLineBreaks
    // Do a first cleanup of outer whitespaces through 'trim' method.
    .trim()
    .replace(REGEX_SENTENCE_SPLIT, REPLACE_SENTENCE_SPLIT_WITH_MARKER)
    .split('###');

interface FeedbackProps {
  positive: boolean;
  questionType: QuestionType;
}

const Feedback: FunctionComponent<FeedbackProps> = ({
  positive,
  questionType,
}) => {
  const feedbacks = useSelector(getFeedbacks);
  const translate = useSelector(getTranslator);

  const fallbackOption = translate(
    positive ? 'fallback_positive_feedback' : 'fallback_negative_feedback'
  );

  const feedbacksForQuestionTypeAndPositivity = feedbacks.find(
    (feedback) =>
      feedback.questionType === questionType &&
      (positive ? feedback.type === 'positive' : feedback.type === 'negative')
  );

  const [randomOption, setRandomOption] = useState<string[]>([]);

  useEffect(() => {
    if (!feedbacksForQuestionTypeAndPositivity) {
      setRandomOption(breakString(fallbackOption));
    } else {
      const { options } = feedbacksForQuestionTypeAndPositivity;

      if (options.length === 0) {
        setRandomOption(breakString(fallbackOption));
      } else {
        const optionIndex = Math.floor(Math.random() * options.length);
        setRandomOption(breakString(options[optionIndex]));
      }
    }
  }, [fallbackOption, feedbacksForQuestionTypeAndPositivity]);

  return (
    <div className='m-feedback highlight'>
      {positive === true ? (
        <SuperHappyIcon />
      ) : (
        <i
          className='a-icon boschicon-bosch-ic-emoji-very-sad'
          title='Lorem Ipsum'
        ></i>
      )}
      <div className='m-feedback__text'>
        {randomOption.map((text, index, list) => (
          <Fragment key={index}>
            {text}
            {index !== list.length - 1 && <br />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Feedback;
