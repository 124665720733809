import { FunctionComponent } from 'react';
import { IntroContent } from '../../../lib/model';
import { selectLanguage, startQuiz } from '../../../state/actions';
import { useDispatch, useSelector } from '../../../state/hooks';

import Button from '../../Button/Button';
import Card from '../CardContainer/Card/Card';
import CardContainer from '../CardContainer/CardContainer';
import { getCurrentLanguage, hasQuizStarted } from '../../../state/selector';
import { allowedLanguages } from '../../../lib/language';

export interface IntroProps {
  content: IntroContent;
}

const Intro: FunctionComponent<IntroProps> = ({ content }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const quizHasStarted = useSelector(hasQuizStarted);

  const dispatch = useDispatch();

  const {
    headline,
    text,
    hinweisText,
    cta,
    modulHeadline,
    modulText,
    linkmoduls,
  } = content;

  return (
    <div className='m-intro'>
      <h1>{headline}</h1>
      <figure className='a-image'>
        <div className='a-image__ratioWrapper'>
          <img
            src='images/Start-End-Screen_Images/Start_Title_Image.png'
            alt='Lorem ipsum dolor sit amet'
          />
        </div>
      </figure>
      <p className='m-intro__paragraph'>{text}</p>
      <p className='m-intro__disclaimer'>{hinweisText}</p>
      <div className='m-intro__buttons'>
        {!quizHasStarted && (
          <div className='a-dropdown'>
            <select
              onChange={({ target: { value } }) =>
                dispatch(selectLanguage(value))
              }
              value={currentLanguage}
            >
              {allowedLanguages.map(({ id, label }) => (
                <option value={id} key={id}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )}
        <Button
          type='primary'
          icon='arrow-right'
          label={cta}
          onClick={() => dispatch(startQuiz())}
        />
      </div>
      <CardContainer headline={modulHeadline} text={modulText}>
        {linkmoduls.map((card) => (
          <Card link={card.cta} key={card.copy + card.cta.url} />
        ))}
      </CardContainer>
    </div>
  );
};

export default Intro;
