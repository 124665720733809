import quizDe from "../content/quiz_de.json";
import quizEn from "../content/quiz_en.json";
import { Content, Question, Translations, Feedbacks } from "./model";

const questions = {
  de_DE: ((quizDe as any).questions as unknown) as Question[],
  en_US: ((quizEn as any).questions as unknown) as Question[],
} as { [key: string]: Question[] };

const content = {
  de_DE: ((quizDe as any).content as unknown) as Content,
  en_US: ((quizEn as any).content as unknown) as Content,
} as { [key: string]: Content };

const feedbacks = {
  de_DE: ((quizDe as any).feedbacks as unknown) as Feedbacks[],
  en_US: ((quizEn as any).feedbacks as unknown) as Feedbacks[],
} as { [key: string]: Feedbacks[] };

export const translations = {
  de_DE: ((quizDe as any).translations as unknown) as Translations,
  en_US: ((quizEn as any).translations as unknown) as Translations,
} as { [key: string]: Translations };

export const loadQuestions = (language: string): Question[] => {
  return questions[language] || [];
};

export const loadContent = (language: string): Content => {
  return content[language];
};

export const loadFeedbacks = (language: string): Feedbacks[] => {
  return feedbacks[language];
};