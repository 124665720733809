import { FunctionComponent } from 'react';
import { useSelector } from '../../../state/hooks';

import { getTranslator } from '../../../state/selector';

import Button from '../../Button/Button';

interface BonusRoundIntroProps {
  onStart: () => void;
}

const BonusRoundIntro: FunctionComponent<BonusRoundIntroProps> = ({
  onStart,
}) => {
  const translate = useSelector(getTranslator);

  return (
    <div className='m-bonus-round__intro'>
      <div className='m-bonus-round__intro__content'>
        <i className='m-bonus-round__intro__icon a-icon boschicon-bosch-ic-speedometer-high'></i>
        <div className='m-bonus-round__intro__headline highlight'>
          {translate('bonusRound_start_headline')}
        </div>
        <div className='m-bonus-round__intro__kicker highlight'>
          {translate('bonusRound_start_subheadline')}
        </div>
      </div>
      <div className='m-bonus-round__cta'>
        <Button
          icon='arrow-right'
          label={translate('bonusRound_start_cta')}
          type='primary'
          onClick={onStart}
        />
        <div className='m-bonus-round__intro__instructions'>
          {translate('bonusRound_start_copy')}
        </div>
      </div>
    </div>
  );
};

export default BonusRoundIntro;
