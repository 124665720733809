import { FunctionComponent, ReactNode } from "react";
import classNames from "classnames";

type AllowedIconName = "alarm-clock" | "wishlist" | "medal" | "speedometer-high";
interface TextIconProps {
  icon: AllowedIconName;
  children: ReactNode;
}

const TextIcon: FunctionComponent<TextIconProps> = ({
  icon,
  children
}) => {
  const textIconClass = classNames(
    `a-text-icon__icon a-icon boschicon-bosch-ic-${icon}`
  );
  return (
    <div className="a-text-icon">
      <i className={textIconClass} data-title={icon}></i>
      <span className="a-text-icon__text">{children}</span>
    </div>
  )
}

export default TextIcon;