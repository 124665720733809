import {
  BonusRoundAnswer,
  BonusRoundQuestion,
  BonusRoundAnswerValue,
  BonusRoundOption,
} from "../model";

export const numberOfCorrectBonusRoundAnswers = (
  { id, options }: BonusRoundQuestion,
  { values, questionId }: BonusRoundAnswer
): number => {
  if (id !== questionId) return 0;

  let count = 0;

  values.forEach((value) => {
    if (valueIsCorrect(value, options)) {
      count = count + 1;
    }
  });

  return count;
};

const valueIsCorrect = (
  value: BonusRoundAnswerValue,
  options: BonusRoundOption[]
): boolean => {
  const optionForValue = options.find((option) => option.id === value.optionId);

  if (!optionForValue) {
    return false;
  }

  return optionForValue.correctSelection === value.selected;
};

/**
 * @returns
 * - true if the last answer was correct
 * - false if the last answer was not correct
 * - null if there is no last answer
 */
export const lastAnswerWasCorrect = (
  values: BonusRoundAnswerValue[],
  options: BonusRoundOption[],
): boolean | null => {
  if (values.length === 0) {
    // there cannot be a "last answer" if there is none
    return null;
  }

  const lastAnswer = values[values.length - 1];

  return valueIsCorrect(lastAnswer, options);
}
