import { FunctionComponent, useEffect } from 'react';
import Confetti from 'react-confetti';

import Intro from './Intro/Intro';
import Outro from './Outro/Outro';

import useWindowSize from 'react-use/lib/useWindowSize';

import { useDispatch, useSelector } from '../../state/hooks';
import {
  getContent,
  getCurrentAnswer,
  getCurrentLanguage,
  getCurrentQuestion,
  getShowUnselectedAnswerWarning,
  hasQuizEnded,
  hasQuizStarted,
  shouldShowHint,
  getBonusRoundHasStarted,
  getBonusRoundHasEnded,
} from '../../state/selector';
import { Question } from '../Question/Question';
import { setContent, setQuestions, setFeedbacks } from '../../state/actions';
import { loadContent, loadQuestions, loadFeedbacks } from '../../lib/loadData';
import Header from '../Header/Header';
import { QuestionType } from '../../lib/model';

const Main: FunctionComponent = () => {
  const dispatch = useDispatch();

  const quizHasStarted = useSelector(hasQuizStarted);
  const quizHasEnded = useSelector(hasQuizEnded);
  const bonusRoundHasStarted = useSelector(getBonusRoundHasStarted);
  const bonusRoundHasEnded = useSelector(getBonusRoundHasEnded);

  const currentQuestion = useSelector(getCurrentQuestion);
  const currentAnswer = useSelector(getCurrentAnswer);
  const showUnselectedAnswerWarning = useSelector(
    getShowUnselectedAnswerWarning
  );

  const showDialog = useSelector(shouldShowHint);

  const currentLanguage = useSelector(getCurrentLanguage);

  const content = useSelector(getContent);

  // load questions when currentLanguage changes
  useEffect(() => {
    dispatch(setQuestions(loadQuestions(currentLanguage)));
    dispatch(setContent(loadContent(currentLanguage)));
    dispatch(setFeedbacks(loadFeedbacks(currentLanguage)));
  }, [currentLanguage, dispatch]);

  // scroll to top if the question changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [currentQuestion]);

  // Konfetti's settings
  const { width, height } = useWindowSize(); // Resize the confetti if the user resize the page
  // Make the confetti based on Bosch's colors
  const boschColors = ['#9E2896', '#007BC0', '#18837E', '#00884A'];
  return (
    <>
      {quizHasStarted && quizHasEnded && content && content.outro && (
        <Confetti
          colors={boschColors}
          width={width}
          height={height}
          numberOfPieces={2000}
          tweenDuration={20000}
          gravity={0.06}
          recycle={false}
        />
      )}
      {showDialog && <div className='a-modal-background'></div>}
      {quizHasStarted &&
        currentQuestion &&
        currentQuestion.type === QuestionType.QUESTION_TYPE_BONUS_ROUND && (
          <>
            <div className='a-modal__bonus-round -contrast'></div>
            {bonusRoundHasStarted && bonusRoundHasEnded && (
              <Confetti
                style={{ zIndex: 99 }}
                colors={boschColors}
                width={width}
                height={height}
                numberOfPieces={2000}
                tweenDuration={20000}
                gravity={0.06}
                recycle={false}
              />
            )}
          </>
        )}
      <Header></Header>
      <main className='o-main'>
        <div className='e-container'>
          {!quizHasStarted && content && content.intro && (
            <Intro content={content.intro} />
          )}
          {quizHasStarted && !quizHasEnded && (
            <Question
              question={currentQuestion!}
              answer={currentAnswer}
              showUnselectedAnswerWarning={showUnselectedAnswerWarning}
            />
          )}
          {quizHasStarted && quizHasEnded && content && content.outro && (
            <Outro content={content.outro} />
          )}
        </div>
      </main>
    </>
  );
};

export default Main;
