import { FunctionComponent } from "react";

const Badge: FunctionComponent = () => {
  return (
    <div className="a-badge">
      <i className="a-badge__icon a-icon boschicon-bosch-ic-checkmark"></i>
    </div>
  );
};

export default Badge;
