import {
  BasicAnswer,
  BonusRoundAnswer,
  GuessingAnswer,
  GuessingQuestion,
  Question,
  QuestionType,
} from "../model";

/**
 * @returns  a new initial answer object for the given question
 */
export const createInitialAnswer = (
  question: Question
): BasicAnswer | GuessingAnswer => {
  const basicAnswer: BasicAnswer = {
    acknowledged: false,
    confirmedAt: null,
    optionIds: [],
    questionId: question.id,
    startedAt: Date.now(),
  };

  switch (question.type) {
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT:
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_IMAGE:
    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT:
    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE:
    case QuestionType.QUESTION_TYPE_FINDING_THINGS:
      // all these types do not need anything more
      return basicAnswer;
    case QuestionType.QUESTION_TYPE_GUESSING:
      return {
        ...basicAnswer,
        // "guessing" has an initial value that needs to be set
        value: (question as GuessingQuestion).initial,
      };
    case QuestionType.QUESTION_TYPE_BONUS_ROUND:
      return {
        ...basicAnswer,
        // bonus rounds need another store for the given values
        values: [],
        startedAt: null,
      } as BonusRoundAnswer;
  }
};
