import Main from "./components/Main/Main"

import { Provider } from "react-redux";
import store from "./state/store";

import "./App.scss";

function App() {
  return (
    <Provider store={store}>
      <Main></Main>
    </Provider>
  );
}

export default App;