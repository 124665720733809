import { FunctionComponent, useRef } from 'react';

import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import Feedback from '../Feedback/Feedback';
import Dialog from '../Dialog/Dialog';

import { FindingThingsQuestion, Answer } from '../../lib/model';

import { useDispatch } from 'react-redux';
import {
  acknowledgeResult,
  confirmAnswer,
  selectOption,
  showHint,
} from '../../state/actions';

import { useSelector } from '../../state/hooks';
import {
  currentAnswerIsCorrect,
  getShowUnselectedAnswerWarning,
  getTranslator,
  shouldShowHint,
} from '../../state/selector';

/**
 * the clicks that are these many pixels away from an option's coordinates
 * are counted as hit.
 *
 * Note: This is _not_ a circle. @see distance
 */
const RADIUS = 36;

interface Point {
  x: number;
  y: number;
}

/**
 * @returns the distance between the two given points
 */
const distance = (a: Point, b: Point): number => {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
};

/**
 * returns a point where the input has been read as percentages of the given
 * element's width and height
 */
const percentageToPixels = (element: HTMLElement, point: Point): Point => {
  const height = element.offsetHeight;
  const width = element.offsetWidth;

  return {
    x: (point.x / 100) * width,
    y: (point.y / 100) * height,
  };
};

export interface FindingThingsProps {
  question: FindingThingsQuestion;
  answer: Answer;
}

const FindingThings: FunctionComponent<FindingThingsProps> = ({
  question,
  answer,
}) => {
  const dispatch = useDispatch();

  const correct = useSelector(currentAnswerIsCorrect);
  const showWarning = useSelector(getShowUnselectedAnswerWarning);

  const clickTargetCanvas = useRef<HTMLUListElement>(null);

  const translate = useSelector(getTranslator);

  const showDialog = useSelector(shouldShowHint);

  const clickImage = (event: MouseEvent) => {
    if (clickTargetCanvas.current === null) {
      // if there is no reference to our click canvas yet, abort
      return;
    }

    if (clickTargetCanvas.current !== event.target) {
      // if something else (like a badge) was clicked, do nothing
      return;
    }

    // convert all percentage values to pixels
    const optionsWithPixelValues = question.options.map((option) => {
      const { x, y } = percentageToPixels(clickTargetCanvas.current!, {
        x: option.x,
        y: option.y,
      });

      return {
        ...option,
        x,
        y,
      };
    });

    const clickTarget: Point = {
      x: event.offsetX,
      y: event.offsetY,
    };

    // look for options that are inside the click radius
    const hitOptions = optionsWithPixelValues.filter(({ x, y }) => {
      return distance(clickTarget, { x, y }) < RADIUS;
    });

    hitOptions.forEach((option) => {
      dispatch(selectOption(option.id));
    });
  };

  return (
    <div className='m-finding-things'>
      {answer.confirmedAt !== null && (
        <Feedback positive={correct} questionType={question.type} />
      )}
      {answer.confirmedAt === null && (
        <h1 className='m-finding-things__headline'>{question.headline}</h1>
      )}
      <div className='m-finding-things__image-container'>
        <figure className='m-finding-things__image a-image'>
          <div
            className='a-image__ratioWrapper'
            onClick={(event) => clickImage(event.nativeEvent)}
          >
            <img src={question.imageUrl} alt='FINDING THINGS' />
          </div>
          <ul
            className='m-finding-things__badges'
            onClick={(event) => clickImage(event.nativeEvent)}
            ref={clickTargetCanvas}
          >
            {question.options
              .filter(
                (option) =>
                  answer.confirmedAt !== null ||
                  answer.optionIds.includes(option.id)
              )
              .map((option) => {
                return (
                  <li
                    key={option.id}
                    style={{
                      left: `${option.x}%`,
                      top: `${option.y}%`,
                      position: 'absolute',
                    }}
                  >
                    <Badge />
                  </li>
                );
              })}
          </ul>
        </figure>
        {answer.confirmedAt !== null && (
          <div className='a-notification -neutral' role='alert'>
            <div
              id='notification-label-id-bar-default-notification-neutral'
              className='a-notification__content'
            >
              {question.explanation}
            </div>
          </div>
        )}
      </div>
      {showWarning && (
        <div className='a-notification -warning' role='alert'>
          <i className='a-icon ui-ic-alert-warning' title='Loren Ipsum'></i>
          <div
            id='notification-label-id-bar-warning-warning'
            className='a-notification__content'
          >
            {translate('show_notification_warning')}
          </div>
        </div>
      )}
      <div className='m-finding-things__buttons'>
        {null === answer.confirmedAt && (
          <>
            <Button
              type='primary'
              label={translate('answer_cta')}
              onClick={() => dispatch(confirmAnswer())}
            ></Button>
            <Button
              icon='wand-user'
              type='secondary'
              label={translate('help_cta')}
              onClick={() => dispatch(showHint())}
            ></Button>
          </>
        )}
        {null !== answer.confirmedAt && (
          <>
            <Button
              icon='arrow-right'
              label={translate('next_question_cta')}
              type='primary'
              onClick={() => dispatch(acknowledgeResult())}
            ></Button>

            <div className='a-link a-link--button-secondary -icon'>
              <a
                aria-label='Open Look-alike secondary button with icon right Link Label'
                href={question.hint}
                target='_blank'
                rel='noreferrer'
              >
                <i
                  className='a-icon boschicon-bosch-ic-externallink'
                  title='external-link'
                ></i>
                <span>{translate('know_more_cta')}</span>
              </a>
            </div>
          </>
        )}
      </div>
      <Dialog hint={question.hint} open={showDialog} />
    </div>
  );
};

export default FindingThings;
