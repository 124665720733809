import { FunctionComponent } from 'react';
import Button from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { hideHint } from '../../state/actions';

import { getTranslator } from '../../state/selector';

interface DialogProps {
  hint?: string;
  open: boolean;
}

const Dialog: FunctionComponent<DialogProps> = ({ hint, open }) => {
  const dispatch = useDispatch();

  const translate = useSelector(getTranslator);

  return (
    <>
      <dialog
        open={open}
        className='m-dialog -floating-shadow-s'
        id='frontend-kit-dialog-default-non-modal-dialog'
        aria-labelledby='dialog-default-non-modal-dialog-title'
      >
        <div className='m-dialog__header'>
          <div className='m-dialog__title'>
            {translate('show_modal_superline')}
          </div>
          <Button
            icon='close'
            type='integrated'
            onClick={() => dispatch(hideHint())}
          />
        </div>
        <hr className='a-divider' />
        <div className='m-dialog__content'>
          <div
            className='m-dialog__headline'
            id='frontend-kit-dialog-default-non-modal-dialog-title'
          >
            <i className='a-icon boschicon-bosch-ic-alarm-clock'></i>
            {translate('show_modal_headline')}
          </div>
          <div
            className='m-dialog__body'
            id='frontend-kit-dialog-default-non-modal-dialog-description'
          >
            {translate('show_modal_copy')}
          </div>
          <div className='m-dialog__actions'>
            <div className='a-link a-link--button -icon'>
              <a
                aria-label='Open Look-alike secondary button with icon right Link Label'
                href={hint}
                target='_blank'
                rel='noreferrer'
              >
                <span>{translate('show_modal_cta')}</span>
              </a>
            </div>
            <Button
              label={translate('show_modal_back')}
              type='secondary'
              onClick={() => dispatch(hideHint())}
            />
          </div>
        </div>
      </dialog>
    </>
  );
};

export default Dialog;
