import { FunctionComponent, useEffect } from 'react';
import { useSelector } from '../../../state/hooks';
import { getTranslator } from '../../../state/selector';

interface SliderPointsProps {
  minPoints: number;
  maxPoints: number;
  value: number;
  step?: number;
  onChange: (value: number) => void;
}

const SliderPoints: FunctionComponent<SliderPointsProps> = ({
  minPoints,
  maxPoints,
  value,
  step = 1,
  onChange,
}) => {
  const translate = useSelector(getTranslator);

  const calculatePercentage = (value: number) =>
    ((value - minPoints) / (maxPoints - minPoints)) * 100;

  useEffect(() => {
    const slider = document.querySelector(
      '.m-slider-points__bottom__slider'
    ) as HTMLInputElement;
    if (slider) {
      const percentage = calculatePercentage(value);
      slider.style.setProperty('--slider-percentage', `${percentage}`);
    }
  });

  return (
    <div className='m-slider-points'>
      <div className='m-slider-points__slider-container'>
        {translate('guessing_slider_points', {
          one: minPoints,
          two: maxPoints,
        })}
        <div className='m-slider-points__bottom__slider a-slider'>
          <input
            type='range'
            min={minPoints}
            max={maxPoints}
            step={step}
            value={value}
            onChange={({ target: { value } }) => onChange(parseFloat(value))}
          />
        </div>
      </div>
      <div className='m-slider-points__result -size-3xl highlight'>
        {value}
      </div>
    </div>
  );
};

export default SliderPoints;
