import { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';

import TextIcon from '../../TextIcon/TextIcon';
import Button from '../../Button/Button';

import {
  BonusRoundAnswer,
  BonusRoundOption,
  BonusRoundQuestion,
} from '../../../lib/model';
import { useSelector } from '../../../state/hooks';
import { getTranslator } from '../../../state/selector';

import { liveScoreWithoutTimeFactor } from '../../../lib/question/score';
import { lastAnswerWasCorrect } from '../../../lib/question/numberOfCorrectBonusRoundAnswers';
import SuperHappyIcon from '../../SuperHappyIcon/SuperHappyIcon';

interface BonusRoundContentProps {
  onLeft: () => void;
  onRight: () => void;
  option: BonusRoundOption;
  question: BonusRoundQuestion;
  answer: BonusRoundAnswer;
}

const BonusRoundContent: FunctionComponent<BonusRoundContentProps> = ({
  onLeft,
  onRight,
  option,
  question,
  answer,
}) => {
  const translate = useSelector(getTranslator);

  const bonusRoundScore = liveScoreWithoutTimeFactor(question, answer);

  useEffect(() => {
    const keyEventListener = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onLeft();
      }
      if (event.key === 'ArrowRight') {
        onRight();
      }
    };

    document.addEventListener('keydown', keyEventListener);

    return () => document.removeEventListener('keydown', keyEventListener);
  }, [onLeft, onRight]);

  const wasCorrect = lastAnswerWasCorrect(answer.values, question.options);

  const hasLastAnswer = wasCorrect !== null;

  const shouldShowGoodFeedback = hasLastAnswer && wasCorrect;
  const shouldShowBadFeedback = hasLastAnswer && !wasCorrect;

  const feedbackIconClass = classNames([
    'm-bonus-round__content__feedback-icon',
    {
      '-good': shouldShowGoodFeedback,
      '-bad': shouldShowBadFeedback,
    },
  ]);

  return (
    <div className='m-bonus-round__content'>
      <div className='m-bonus-round__content-container'>
        <div className='m-bonus-round__content__textIcons'>
          <TextIcon icon='speedometer-high'>
            {translate('bonusRound_iconText')}
          </TextIcon>
          <TextIcon icon='medal'>
            {bonusRoundScore.toLocaleString('de')}
          </TextIcon>
        </div>
        <h4 className='m-bonus-round__content__headline'>
          {translate('bonusRound_start_subheadline')}
        </h4>
        <figure className='m-bonus-round__content__image-container a-image'>
          <div className='a-image__ratioWrapper'>
            <img src={option.imageUrl} alt='BONUS ROUND' />
          </div>
        </figure>
      </div>

      <div key={answer.values.length} className={feedbackIconClass}>
        {shouldShowGoodFeedback && (
          <div className='m-bonus-round__content__feedback -good'>
            <SuperHappyIcon></SuperHappyIcon>
            <span className='highlight'>{translate('bonusRound_question_feedback_good')}</span>
          </div>
        )}
        {shouldShowBadFeedback && (
          <div className='m-bonus-round__content__feedback -bad'>
            <i className='a-icon boschicon-bosch-ic-emoji-very-sad'></i>
            <span className='highlight'>{translate('bonusRound_question_feedback_bad')}</span>
          </div>
        )}
        <div className='m-bonus-round__content__buttons'>
          <Button
            label={translate('bonusRound_question_leftCta')}
            type='secondary'
            icon='arrow-left-frame'
            onClick={onLeft}
          />
          <Button
            label={translate('bonusRound_question_rightCta')}
            type='primary'
            icon='arrow-right-frame'
            onClick={onRight}
          />
        </div>
      </div>
    </div>
  );
};

export default BonusRoundContent;
