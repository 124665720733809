import { FunctionComponent } from "react";
import classNames from "classnames";

type AllowedButtonType = "primary" | "secondary" | "tertiary" | "integrated";
interface BasicButtonProps{
  type: AllowedButtonType;
  onClick?: () => void;
}

interface ButtonWithIcon extends BasicButtonProps {
  icon: string;
  label?: string;
}

interface ButtonWithLabel extends BasicButtonProps {
  icon?: string;
  label: string;
}

type ButtonProps = ButtonWithIcon | ButtonWithLabel;

const Button: FunctionComponent<ButtonProps> = ({
  type,
  icon = null,
  label = null,
  onClick = () => {},
}) => {
  const buttonClass = classNames([
    "a-button",
    "a-button--" + type,
    { "-without-icon": null === icon },
    { "-without-label": null === label },
  ]);
  return (
    <button type="button" className={buttonClass} onClick={onClick}>
      {icon && (
        <i className={`a-icon a-button__icon boschicon-bosch-ic-${icon}`}></i>
      )}
      {label && <div className="a-button__label">{label}</div>}
    </button>
  );
};

export default Button;
