import { StateModel } from "./store";
import { isComplete } from "../lib/question/isComplete";
import { getAnswer } from "../lib/question/getAnswer";
import { score } from "../lib/question/score";
import { timeElapsed } from "../lib/answer/timeElapsed";
import {
  Answer,
  Feedbacks,
  BonusRoundAnswer,
  BonusRoundQuestion,
  FindingThingsQuestion,
  GuessingAnswer,
  GuessingQuestion,
  Question,
  QuestionType,
  BonusRoundOption,
} from "../lib/model";
import { createTranslate, Translator } from "../content/translate";
import { translations } from "../lib/loadData";

/**
 * @returns the current language
 */
export const getCurrentLanguage = ({ currentLanguage }: StateModel) =>
  currentLanguage;

/**
 * @returns true once the quiz has been started
 */
export const hasQuizStarted = ({ quizHasStarted }: StateModel) =>
  quizHasStarted;

/**
 * @returns true once the quiz has ended - when there is no question to answer anymore
 */
export const hasQuizEnded = (state: StateModel) =>
  undefined === getCurrentQuestion(state);

/**
 * @returns the question that is currently waiting for an answer
 */
export const getCurrentQuestion = ({ questions, answers }: StateModel) =>
  questions.find((question) => !isComplete(question, answers));

/**
 * @returns the answer object for the current question, if there is one, or null if
 * * there is no current question
 * * the current question does not have an answer yet
 */
export const getCurrentAnswer = (state: StateModel) => {
  const { answers } = state;
  const currentQuestion = getCurrentQuestion(state);

  if (!currentQuestion) {
    return null;
  }

  const answer = getAnswer(currentQuestion, answers);

  return answer;
};

/**
 * @returns true if the warning "select an answer first" should be shown
 */
export const getShowUnselectedAnswerWarning = ({
  showUnselectedAnswerWarning,
}: StateModel) => showUnselectedAnswerWarning;

/**
 * @returns the current quiz progress, in percent
 */
export const getProgress = (state: StateModel): number => {
  const total = state.questions.length;
  const complete = state.questions.filter((question) =>
    isComplete(question, state.answers)
  ).length;

  return (complete / total) * 100;
};

/**
 * @returns the content
 */
export const getContent = ({ content }: StateModel) => content;

/**
 * @returns true if the current answer
 * - is confirmed
 * - is "correct" in the sense that only correct options and all correct options have been selected
 */
export const currentAnswerIsCorrect = (state: StateModel): boolean => {
  const currentQuestion = getCurrentQuestion(state);
  const currentAnswer = getCurrentAnswer(state);

  if (
    !currentQuestion ||
    !currentAnswer ||
    null === currentAnswer.confirmedAt
  ) {
    return false;
  }

  switch (currentQuestion.type) {
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_TEXT:
    case QuestionType.QUESTION_TYPE_SINGLE_CHOICE_IMAGE: {
      const [optionId] = currentAnswer.optionIds;
      const options = currentQuestion.options as {
        isCorrect: boolean;
        id: string;
      }[];

      const correctOption = options.find(({ isCorrect }) => isCorrect);

      if (!correctOption) {
        return false;
      }

      return correctOption.id === optionId;
    }
    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_IMAGE:
    case QuestionType.QUESTION_TYPE_MULTIPLE_CHOICE_TEXT: {
      const options = currentQuestion.options as {
        isCorrect: boolean;
        id: string;
      }[];

      const correctOptions = options.filter(({ isCorrect }) => isCorrect);

      const correctOptionIds = correctOptions.map(({ id }) => id);

      return (
        correctOptionIds.every((correctOptionId) =>
          currentAnswer.optionIds.includes(correctOptionId)
        ) &&
        currentAnswer.optionIds.every((selectedId) =>
          correctOptionIds.includes(selectedId)
        )
      );
    }
    case QuestionType.QUESTION_TYPE_GUESSING: {
      const selectedValue = (currentAnswer as GuessingAnswer).value;
      const minimumCorrectValue = (currentQuestion as GuessingQuestion)
        .minCorrect;
      const maximumCorrectValue = (currentQuestion as GuessingQuestion)
        .maxCorrect;

      return (
        minimumCorrectValue <= selectedValue &&
        maximumCorrectValue >= selectedValue
      );
    }
    case QuestionType.QUESTION_TYPE_FINDING_THINGS: {
      const selectedOptionIds = currentAnswer.optionIds;
      const optionIds = (currentQuestion as FindingThingsQuestion).options.map(
        ({ id }) => id
      );

      return optionIds.every((id) => selectedOptionIds.includes(id));
    }
    // @TODO add more question types here
    default:
      return false;
  }
};

/**
 * @returns a translator for the current language
 */
export const getTranslator = ({ currentLanguage }: StateModel): Translator =>
  createTranslate(translations, currentLanguage);

export const quizTime = ({ answers }: StateModel): number =>
  answers.reduce((total, answer) => total + timeElapsed(answer), 0);

export const quizTimeForCompletedAnswers = (state: StateModel): number => {
  const { answers } = state;
  const confirmedAnswers = answers.filter(
    (answer) => answer.confirmedAt !== null
  );
  return quizTime({
    ...state,
    answers: confirmedAnswers,
  });
};

const scoreSum = (questions: Question[], answers: Answer[]): number => {
  return questions.reduce((partialScore, question) => {
    const answer = getAnswer(question, answers);
    if (!answer) {
      return partialScore;
    }
    return partialScore + score(question, answer);
  }, 0);
};

export const getBonusScore = (state: StateModel): number => {
  const bonusQuestions = state.questions.filter(
    (question) => question.type === QuestionType.QUESTION_TYPE_BONUS_ROUND
  );

  return scoreSum(bonusQuestions, state.answers);
};

export const getNonBonusScore = (state: StateModel): number => {
  const nonBonusQuestions = state.questions.filter(
    (question) => question.type !== QuestionType.QUESTION_TYPE_BONUS_ROUND
  );

  return scoreSum(nonBonusQuestions, state.answers);
};

export const getQuizScore = ({ questions, answers }: StateModel): number => {
  return scoreSum(questions, answers);
};

/**
 * @returns true once the hint has been shown
 */
export const shouldShowHint = ({ showHint }: StateModel) => {
 return showHint;
}

export const getFeedbacks = (state: StateModel): Feedbacks[] => {
  return state.feedbacks;
}

export const getBonusRoundHasStarted = (state: StateModel) => {
  const currentQuestion = getCurrentQuestion(state);

  if (
    !currentQuestion ||
    currentQuestion.type !== QuestionType.QUESTION_TYPE_BONUS_ROUND
  ) {
    return false;
  }

  // from here on: currentQuestion is not null and currentQuestion is a bonus round question

  const currentAnswer = getCurrentAnswer(state);

  if (!currentAnswer) {
    return false;
  }

  return currentAnswer.startedAt !== null;
};

export const getBonusRoundHasEnded = (state: StateModel) => {
  const currentQuestion = getCurrentQuestion(state);

  if (
    !currentQuestion ||
    currentQuestion.type !== QuestionType.QUESTION_TYPE_BONUS_ROUND
  ) {
    return false;
  }

  const currentAnswer = getCurrentAnswer(state);

  if (!currentAnswer) {
    return false;
  }

  return currentAnswer.confirmedAt !== null;
};

export const getCurrentBonusRoundOption = (state: StateModel): BonusRoundOption | null => {
  const currentQuestion = getCurrentQuestion(state);

  if (
    !currentQuestion ||
    currentQuestion.type !== QuestionType.QUESTION_TYPE_BONUS_ROUND
  ) {
    return null;
  }

  const currentAnswer = getCurrentAnswer(state);

  if (!currentAnswer) {
    return null;
  }

  const optionIdsWithASelection = (
    currentAnswer as BonusRoundAnswer
  ).values.map((value) => value.optionId);

  // find the first option that does not have a selection yet
  const currentOption = (currentQuestion as BonusRoundQuestion).options.find((option) =>
    !optionIdsWithASelection.includes(option.id)
  );

  return currentOption || null;
};
