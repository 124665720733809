import { FunctionComponent } from 'react';
import { format } from 'date-fns/fp';

import { OutroContent } from '../../../lib/model';
import { startQuiz } from '../../../state/actions';
import { useDispatch, useSelector } from '../../../state/hooks';
import {
  getBonusScore,
  getNonBonusScore,
  getQuizScore,
  quizTime,
} from '../../../state/selector';

import Button from '../../Button/Button';
import TextIcon from '../../TextIcon/TextIcon';
import Card from '../CardContainer/Card/Card';
import CardContainer from '../CardContainer/CardContainer';

export interface OutroProps {
  content: OutroContent;
}

const Outro: FunctionComponent<OutroProps> = ({ content }) => {
  const dispatch = useDispatch();

  const elapsed = useSelector(quizTime);

  const timeLabel = format('mm:ss')(elapsed);

  const nonBonusScore = useSelector(getNonBonusScore);
  const bonusScore = useSelector(getBonusScore);
  const score = useSelector(getQuizScore);

  return (
    <div className='m-outro'>
      <h1>{content.headline1}</h1>
      <figure className='a-image'>
        <div className='a-image__ratioWrapper'>
          <img
            src='images/Start-End-Screen_Images/End_Screen_Image.jpg'
            alt='Lorem ipsum dolor sit amet'
          />
        </div>
        <div className='m-outro__info-container'>
          <div className='m-outro__info-container__top'>
            <h2>{content.headline2}</h2>
            <h3>{content.scoreLabel}</h3>
          </div>
          <div className='m-outro__info-container__bottom'>
            <div className='m-outro__score highlight'>
              {score.toLocaleString('de')}
            </div>
            <div className='m-outro__textIcons'>
              <TextIcon icon='alarm-clock'>{timeLabel}</TextIcon>
              <TextIcon icon='wishlist'>
                {nonBonusScore.toLocaleString('de')}
              </TextIcon>
              <TextIcon icon='medal'>
                {bonusScore.toLocaleString('de')}
              </TextIcon>
            </div>
          </div>
        </div>
        <Button
          type='tertiary'
          icon='mail'
          label={content.feedbackLabel}
          onClick={() => window.open(`mailto:${content.feedbackEmail}`)}
        />
      </figure>
      <p
        className='m-outro__paragraph'
        dangerouslySetInnerHTML={{ __html: content.text }}
      ></p>
      <p className='m-outro__disclaimer'>{content.hinweisText}</p>
      <Button
        type='primary'
        icon='reset'
        label={content.cta}
        onClick={() => dispatch(startQuiz())}
      />
      <CardContainer headline={content.modulHeadline} text={content.modulText}>
        {content.linkmoduls.map((card) => (
          <Card link={card.cta} key={card.copy + card.cta.url} />
        ))}
      </CardContainer>
    </div>
  );
};

export default Outro;
